import * as VueRouter from 'vue-router';

import Home from './views/Home.vue'
import Error from './views/Error.vue'
import Default from './views/Default.vue'
import Production from './views/Production.vue'
import Productions from './views/Productions.vue'
import Article from './views/Article.vue'
import Articles from './views/Articles.vue'
//const Home = () => import('./views/Home.vue');
//const About = () => import('./views/About.vue');
//const Contact = () => import('./views/Contact.vue');
//const Error = () => import('./views/Error.vue');
//const Default = () => import('./views/Default.vue');
//const Production = () => import('./views/Production.vue');
//const Productions = () => import('./views/Productions.vue');
//const Article = () => import('./views/Article.vue');
//const Articles = () => import('./views/Articles.vue');
import { alternativeLanguage } from './helpers.js';

const multilanguageroutes = [
  { path: '/', redirect: to => { if(document.documentElement.lang) return document.documentElement.lang;}},
  {
    name: 'Language',
    path: '/:language',
    children: [
      { name: 'Home', path: '', component: Home },
      { name: 'Error', path: 'error', component: Default },
      { name: 'Productions', path: 'productions', component: Productions },
      { name: 'Production', path: 'productions/:production', component: Production },
      { name: 'Articles', path: 'news', component: Articles, children: [
        { name: 'Article', path: ':article', components: { Article } },
      ] },
      { name: 'Default', path: ':primi', component: Default },
      { name: 'Subpage', path: ':primi/:secondi', component: Default },
      { name: 'Detail', path: ':primi/:secondi/:dessert', component: Default },
    ]
  }
]

const routes = [
  { path: '/home', redirect: '/' },
  { name: 'Home', path: '/', component: Home },
  { name: 'Error', path: '/error', component: Default },
  { name: 'Default', path: '/:primi', component: Default },
  { name: 'Subpage', path: '/:primi/:secondi', component: Default },
  { name: 'Detail', path: '/:primi/:secondi/:dessert', component: Default },
]

const otherLanguage  = alternativeLanguage();
const alternativeRoutes = otherLanguage ? multilanguageroutes : routes;
//console.log(otherLanguage, alternativeRoutes);

export const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: alternativeRoutes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      //setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }
})
