const For = {}

const language = document.documentElement.lang;

/* GENERAL QUERY STRUCTURE FOR IMAGES */
For.Images = {
  query: 'page.images',
  select: {
    id: true,
    filename: true,
    url: true,
    width: 'file.width()',
    height: 'file.height()',
    ratio: 'file.ratio()',
    srcset: 'file.srcset([250, 450, 800, 1440])',
    /* webp: 'file.srcset("webp")', */
    /* avif: 'file.srcset("avif")' */
  }
};

/* QUERY STRUCTURE FOR DOCUMENTS */
For.Files = {
  query: 'page.files',
  select: {
    id: true,
    filename: true,
    url: true
  }
};

/* BASE FIELDS FOR PAGES */
const Base = {
  title: language ? 'page.content("' + language + '").title' : true,
  url: language ? 'page.url("' + language + '")' : true,
  enurl: 'page.url("en")',
  fiurl: 'page.url("fi")',
  uid: true,
  cover: true,
  /* coverFile: 'page.cover.toFile', */
  listed: 'page.isListed',
  images: For.Images,
};

/* GENERAL QUERY STRUCTURE FOR CHILDREN */
For.Children = {
  ...Base,
  type: true,
  released: true,
  runtime: true,
  date: true,
  text: true,
  children: {
    query: 'page.children',
    select: {
      ...Base,
      type: true,
      date: true,
      released: true,
      runtime: true,
      additional: language ? 'page.content("' + language + '").additional' : page.additional,
      category: language ? 'page.content("' + language + '").category' : true,
      involvement: language ? 'page.content("' + language + '").involvement' : true,
      text: true
    }
  }
};

/* PAGES */
/* ===== */

For.Default = {
  ...Base,
  text: language ? 'page.content("' + language + '").text.kirbytext' : page.text.kirbytext,
  layout: language ? 'page.content("' + language + '").layout' : true,
}

For.Home = {
  ...Base,
  featured: true,
  more: true,
}

For.Production = {
  ...Base,
  text: 'page.text.kirbytext',
  category: language ? 'page.content("' + language + '").category' : true,
  involvement: language ? 'page.content("' + language + '").involvement' : true,
  description: language ? 'page.content("' + language + '").description.kirbytext' : page.description.kirbytext,
  buttons: language ? 'page.content("' + language + '").buttons' : page.buttons,
  details: true,
  released: language ? 'page.content("' + language + '").released' : page.released,
  additional: language ? 'page.content("' + language + '").additional' : page.additional,
  runtime: true,
  maincredits: language ? 'page.content("' + language + '").maincredits' : true,
  related: true,
  trailer: true,
  gallery: true
}

For.Productions = {
  ...For.Default,
  involvement: language ? 'page.content("' + language + '").involvement' : true,
  categories: language ? 'page.content("' + language + '").categories' : true,
  productions: {
    query: 'page.children',
    select: For.Production,
    pagination: {
      //limit: 5,
    },
  }
};

const ArticleGeneric = {
  ...Base,
  newstitle: language ? 'site.find("news").content("' + language + '").title' : 'page.parent.title',
  date: true,
  text: language ? 'page.content("' + language + '").text.kirbytext' : 'page.text.kirbytext',
  links: true,
  parent: 'page.parent',
};

For.Articles = {
  ...Base,
  newstitle: language ? 'site.find("news").content("' + language + '").title' : true,
  news: {
    query: 'page.children',
    select: ArticleGeneric
  }
}

For.Article = {
  ...ArticleGeneric,
  /* news: {
    query: 'page.parent.children',
    select: ArticleGeneric
  },*/
}

For.Site = {
  title: language ? 'site.content("' + language + '").title' : true,
  follow: language ? 'site.content("' + language + '").follow' : true,
  more: language ? 'site.content("' + language + '").more' : true,
  address: language ? 'site.content("' + language + '").address' : true,
  layout: language ? 'site.content("' + language + '").layout' : true,
  backtotop: language ? 'site.content("' + language + '").backtotop' : true,
  socialmedia: language ? 'site.content("' + language + '").socialmedia' : true,
  gallerytitle: language ? 'site.content("' + language + '").gallerytitle' : true,
  trailertitle: language ? 'site.content("' + language + '").trailertitle' : true,
}

export default {
  For
}
